


















































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Card } from '@/types/cms/components';

/**
 * JobAdvertisementCardFormDialog component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-admin-component-unordered-list-list': () => import(
            '@/components/cms/components/admin/unordered-list/CMSAdminComponentUnorderedListList.vue'
        )
    }
})
export default class JobAdvertisementCardFormDialog extends Vue {
    // Prop of type boolean that defined whether the dialog should be shown or not
    @Prop(Boolean) readonly showDialog: boolean | undefined;

    // Prop that holds the pageCard data
    @Prop({type: Object as () => Card}) readonly editedCardItem: Card | undefined;

    // Prop that holds the id of the edited pageCard
    @Prop(Number) readonly editedCardIndex: number | undefined;

    // An crud string that defines the mode of the dialog
    @Prop(String) readonly dialogMode: string | undefined;

    // Boolean that defines wheter the form data is valid or not
    private formValid = false;

    /**
     * @returns the title for the form
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    get formTitle(): string {
        return (this.dialogMode) ? this.$t('form.titles.' + this.dialogMode + 'Card').toString() : '';
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Saves/Updates or deletes the pageCard
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveCard() {
        if (this.editedCardItem) {
            this.resetFormValidation();
            this.$emit('cardSaved', this.dialogMode, this.editedCardIndex, this.editedCardItem);
        }
    }

    /**
     * Closes the dialog
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private closeDialog() {
        this.resetFormValidation();
        this.$emit('dialogClosed');
    }
}
